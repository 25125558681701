import Vue from 'vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

// flatPickr('#singleDatePicker', {
//     // enableTime: true,
//     // allowInput: true,
//     // dateFormat: "d.m.Y"
//     minDate: "today",
//     dateFormat: "Y-m-d"
// })

// flatPickr('#singleDatePicker', {
//     // enableTime: true,
//     allowInput: true,
//     dateFormat: "d.m.Y"
// })

// const dateRangePicker = flatPickr('#dateRangePicker', {
//     mode: "range",
//     minDate: "today",
//     dateFormat: "Y-m-d",
//     disable: [
//         function(date) {
//             // disable every multiple of 8
//             return !(date.getDate() % 8);
//         }
//     ]
// })


Vue.component('date-picker', flatPickr)
// Vue.component('date-range-picker', dateRangePicker)
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

const authChildRoutes = (prop) => [
    {
        path:'login',
        name: prop + '.login',
        component:() => import('@/components/auth/Login.vue')
    },
    {
        path:'otp',
        name: prop + '.otp',
        component: () => import('@/components/auth/Otp.vue')
    }
]

const otherRoutes = () => [
    {
        path:'/',
        name:'dashboard',
        component:() => import('./components/dashboard/MainDashboard.vue'),
        meta: {  requiresAuth:true }
    },
    {
        path:'/operational-dashboard',
        name:'operational.dashboard',
        component:() => import('./components/dashboard/OperationalDashboard.vue'),
        meta: {  requiresAuth:true }
    },
    {
        path:'/partner',
        name:'partner',
        component:() => import('./components/partner/Partner.vue'),      
        meta: { requiresAuth:true }       
    },
    {
        path:'/rider',
        name:'rider',
        component:() => import('./components/rider/Rider.vue'),   
        meta: { requiresAuth:true }          
    },
    {
        path:'/rider-advanced-salary',
        name:'rider.advanced_salary',
        component:() => import('./components/rider/AdvancedSalary.vue'),   
        meta: { requiresAuth:true }          
    },
    {
        path:'/rider-monthly-salary',
        name:'rider.monthly_salary',
        component:() => import('./components/rider/MonthlySalary.vue'),   
        meta: { requiresAuth:true }          
    },
    {
        path:'/rider-monthly-salary-create',
        name:'rider.monthly_salary_create',
        component:() => import('./components/rider/MonthlySalaryCreate.vue'),   
        meta: { requiresAuth:true }          
    },
    {
        path:'/rider-salary-details',
        name:'rider.salary_details',
        component:() => import('./components/rider/SalaryDetails.vue'),   
        meta: { requiresAuth:true }          
    },
    {
        path:'/hub',
        name:'hub',
        component:() => import('./components/settings/Hub.vue'),        
        meta: { requiresAuth:true }     
    },
    {
        path:'/hub-dashboard',
        name:'hub.dashboard',
        component:() => import('./components/Hub/Dashboard.vue'),        
        meta: { requiresAuth:true }     
    },
    {
        path:'/hub-cost',
        name:'hub.cost',
        component:() => import('./components/Hub/Cost.vue'),        
        meta: { requiresAuth:true }     
    },
    {
        path:'/hub-return-list',
        name:'hub.return.list',
        component:() => import('./components/Hub/ReturnList.vue'),        
        meta: { requiresAuth:true }     
    },
    {
        path:'/hub-return-form',
        name:'hub.return.form',
        component:() => import('./components/Hub/ReturnForm.vue'),        
        meta: { requiresAuth:true }     
    },
    {
        path:'/hub-return-show-order',
        name:'hub.return.show.order',
        component:() => import('./components/Hub/ReturnShowOrder.vue'),        
        meta: { requiresAuth:true }     
    },
    {
        path:'/hub-deposit',
        name:'hub.deposit',
        component:() => import('./components/Hub/Deposit.vue'),        
        meta: { requiresAuth:true }     
    },
    {
        path:'/hub-depositable',
        name:'hub.depositable',
        component:() => import('./components/Hub/Depositable.vue'),        
        meta: { requiresAuth:true }     
    },
    {
        path:'/hub-deposited',
        name:'hub.deposited',
        component:() => import('./components/Hub/DepositedOrder.vue'),        
        meta: { requiresAuth:true }     
    },
    {
        path:'/hub-rider-assign',
        name:'hub.rider_assign',
        component:() => import('./components/Hub/RiderAssign.vue'),        
        meta: { requiresAuth:true }     
    },
    {
        path:'/hub-rider-assigned-order',
        name:'hub.rider_assigned_order',
        component:() => import('./components/Hub/RiderAssignedOrder.vue'),        
        meta: { requiresAuth:true }     
    },
    {
        path:'/hub-rider-assigned-order-details',
        name:'hub.rider_assigned_order_details',
        component:() => import('./components/Hub/AssignedOrderDetails.vue'),        
        meta: { requiresAuth:true }     
    },
    {
        path:'/bank',
        name:'bank',
        component:() => import('./components/settings/Bank.vue'),     
        meta: { requiresAuth:true }         
    },
    {
        path:'/area',
        name:'area',
        component:() => import('./components/settings/Area.vue'),       
        meta: { requiresAuth:true }     
    },
    {
        path:'/banner',
        name:'banner',
        component:() => import('./components/settings/Banner.vue'),       
        meta: { requiresAuth:true }     
    },
    {
        path:'/profile',
        name:'profile',
        component:() => import('./components/settings/Profile.vue'),       
        meta: { requiresAuth:true }     
    },
    {
        path:'/district',
        name:'district',
        component:() => import('./components/settings/District.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/thana',
        name:'thana',
        component:() => import('./components/settings/Thana.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/zone',
        name:'zone',
        component:() => import('./components/settings/Zone.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/zone-area',
        name:'zone.area',
        component:() => import('./components/settings/ZoneArea.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/weight-charge',
        name:'WeightCharge',
        component:() => import('./components/settings/WeightCharge.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/store',
        name:'store',
        component:() => import('./components/settings/Store.vue'),      
        meta: { requiresAuth:true }      
    },
    {
        path:'/reason',
        name:'reason',
        component:() => import('./components/settings/Reason.vue'),      
        meta: { requiresAuth:true }      
    },
    {
        path:'/issue',
        name:'issue',
        component: () => import('./components/settings/issue/List.vue'),          
        meta: { requiresAuth:true }    
    },
    // {
    //     path:'/merchant-charge',
    //     name:'merchant_charge',
    //     component:() => import('./components/merchant/Charge.vue'),  
    //     props: {
    //         content: true
    //     },   
    //     meta: { requiresAuth:true }      
    // },
    {
        path:'/merchant',
        name:'merchant',
        component:() => import('./components/merchant/Merchant.vue'),     
        meta: { requiresAuth:true }      
    },
    {
        path:'/iregular-merchant',
        name:'iregular_merchant',
        component:() => import('./components/merchant/IregularMerchant.vue'),     
        meta: { requiresAuth:true }      
    },
    {
        path:'/merchant-account-change-request',
        name:'merchant_account_change_request',
        component:() => import('./components/merchant/AccountChangeRequest.vue'),     
        meta: { requiresAuth:true }      
    },
    {
        path:'/merchant-parcel-summary',
        name:'merchant_parcel_summary',
        component:() => import('./components/merchant/ParcelSummary.vue'),     
        meta: { requiresAuth:true }      
    },
    {
        path:'/merchant-parcel-summary-details',
        name:'merchant_parcel_summary_details',
        component:() => import('./components/merchant/ParcelSummaryDetails.vue'),     
        meta: { requiresAuth:true }      
    },
    {
        path:'/pickup',
        name:'pickup',
        component:() => import('./components/pickup/Pickup.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/returnable',
        name:'returnable',
        component:() => import('./components/return/Returnable.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/return',
        name:'return',
        component:() => import('./components/return/List.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/return-cancel',
        name:'return.cancel',
        component:() => import('./components/return/Cancel.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/make-return',
        name:'make.return',        
        props: true,
        component:() => import('./components/return/Form.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/return-show',
        name:'return.show',
        component:() => import('./components/return/Show.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/return-request',
        name:'return_request',
        component:() => import('./components/return/ReturnRequest.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/hub-to-rtm',
        name:'hub_to_rtm',
        component:() => import('./components/return/HubToRtm.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/hub-to-rtm-create',
        name:'hub_to_rtm_create',
        component:() => import('./components/return/HubToRtmCreate.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/hub-to-rtm-details',
        name:'hub_to_rtm_details',
        component:() => import('./components/return/HubToRtmDetails.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/order-booking',
        name:'order.booking',
        component:() => import('./components/order/Booking.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/order-process',
        name:'order.process',
        component:() => import('./components/order/Process.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/price-changed-order',
        name:'order.price-changed',
        component:() => import('./components/order/PriceChanged.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/under-review',
        name:'under_reviewg',
        component:() => import('./components/order/UnderReview.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/under-review-form',
        name:'under_review_form',
        component:() => import('./components/order/UnderRevieForm.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/order-requesting',
        name:'order.requesting',
        component:() => import('./components/order/Requesting.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/order',
        name:'order',
        component:() => import('./components/order/Order.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/trash-order',
        name:'trash.order',
        component:() => import('./components/order/Trash.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/issue-order',
        name:'issue.order',
        component:() => import('./components/order/IssueOrder.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/deposit',
        name:'deposit',
        component:() => import('./components/accounts/Deposit.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/payment-request',
        name:'payment.request',
        component:() => import('./components/accounts/PaymentRequest.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/due',
        name:'due',
        component:() => import('./components/accounts/Due.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/make-payment/:id?',
        name:'makePayment',
        component:() => import('./components/accounts/MakePaymentOrder.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/make-return/:id?',
        name:'makeReturn',
        component:() => import('./components/accounts/MakePaymentOrder.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/show-order',
        name:'show-order',
        component:() => import('./components/accounts/ShowOrder.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/paid',
        name:'paid',
        component:() => import('./components/accounts/Paid.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/invoice',
        name:'invoice',
        component:() => import('./components/accounts/Invoice.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/collection',
        name:'collection',
        component:() => import('./components/accounts/Collection.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/final-collection',
        name:'final_collection',
        component:() => import('./components/accounts/FinalCollection.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/charge-adjust',
        name:'charge_adjust',
        component:() => import('./components/accounts/ChargeAdjust.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/collection-report',
        name:'collection_report',
        component:() => import('./components/accounts/CollectionReport.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/adjustment',
        name:'adjustment',
        component:() => import('./components/accounts/Adjustment.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/role',
        name:'role',
        component: () => import('./components/user-management/Role.vue'),
        meta: { requiresAuth:true }    
    },
    {
        path:'/user',
        name:'user',
        component: () => import('./components/user-management/User.vue'),
        meta: { requiresAuth:true }  
    },
    {
        path:'/edit-user',
        name:'editUser',
        component:() => import('./components/user-management/EditUser.vue'),
        meta: { requiresAuth:true }  
    },
    {
        path:'/menu-privilege',
        name:'menu_privilege',
        component:() => import('./components/user-management/MenuPrivilege.vue'),
        meta: { requiresAuth:true }  
    },
    {
        path:'/fraud',
        name:'fraud',
        component: () => import('./components/fraud/Fraud.vue'),          
        meta: { requiresAuth:true }    
    },
    {
        path:'/notice',
        name:'notice',
        component: () => import('./components/settings/Notice.vue'),          
        meta: { requiresAuth:true }    
    },
]

const routes = [
    {
        path: '/auth',
        name: 'auth',
        component: () => import('@/components/layouts/AuthLayout.vue'),
        meta: { auth: false },
        children: authChildRoutes('auth')
    },    
    {
        path: '/',
        name: 'main',
        component: () => import('@/components/layouts/MainLayout.vue'),
        meta: { auth: false },
        children: otherRoutes()
    },    
    {
        path: '*',
        component: () => import('@/components/NotFoundPage.vue'),
        meta: { hideNavigation: true }
    }
];

export const router = new VueRouter({
    mode: "history",
    hash: false,
    base: process.env.BASE_URL,
    hashbang: false,
    routes
})
  
router.beforeEach((to, from, next) => {  
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            next({
                path: '/auth/login',
                query: { redirect: to.fullPath }
            })
        } else {
            next();
        }
    } else {
      next();
    }
})

export default router

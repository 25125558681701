
import Vue from 'vue'
import moment from 'moment'

Vue.filter('dateformat',(arg)=> {
    return moment(arg).format("DD MMM YYYY");
})
  
Vue.filter('timeformat',(arg)=> {
    return moment(arg).format("h:mm a");
})